<template>
  <div
    class="dropdown"
    v-click-outside="close"
  >
    <a
      class="dropdown__btn"
      tabindex="0"
      role="button"
      @click="toggle"
      @keypress.enter="toggle"
      :class="btnClass"
    >
      <slot name="button" />
    </a>
    <div
      class="dropdown__menu"
      :class="{ 'dropdown__menu--closed': !isOpen }"
    >
      <div
        class="dropdown__menu-triangle"
        :class="boxAndTriangleClass"
      />
      <div
        class="dropdown__menu-box"
        :class="boxAndTriangleClass"
      >
        <slot name="menu" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    boxAndTriangleClass: { type: String, default: null },
    btnClass: { type: String, default: null },
    forceClose: { type: Boolean, default: true },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    close() {
      this.isOpen = false;
    },
  },
  watch: {
    forceClose(newVal) {
      if (newVal) {
        this.close();
      }
    },
  },
});
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  align-items: center;
  height: 100%;

  &__btn {
    cursor: pointer;
  }

  &__menu {
    position: absolute;
    right: 0;
    z-index: 20;
    text-align: center;
    width: 100%;

    &--closed {
      display: none;
    }
  }

  &__menu-triangle {
    position: absolute;
    width: 2rem;
    height: 2rem;
    border-radius: .375rem;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .05);
    transform: rotate(45deg);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  &__menu-box {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin-top: .5rem;
    border-radius: .375rem;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .05);
    width: 100%;
  }
}
</style>
