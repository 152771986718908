import { api } from '../index';

function create(answersUrl: string, answers: []) {
  return api({
    method: 'post',
    url: answersUrl,
    data: {
      answers,
    },
  }).then(response => response.data);
}

export { create };
