<template>
  <div>
    <slot :pinned="pinned" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Headroom from 'headroom.js';

export default defineComponent({
  props: {
    hold: Boolean,
  },
  data() {
    return {
      pinned: true,
    };
  },
  watch: {
    hold(val) {
      if (val) {
        this.headroom.pin();
      }
    },
  },
  mounted() {
    const self = this;
    const options = {
      tolerance: 5,
      offset: 80,
      onPin() {
        self.pinned = true;
      },
      onUnpin() {
        self.pinned = false;
      },
    };
    const test = Headroom.prototype.update;
    Headroom.prototype.update = function () {
      if (self.hold) return;

      test.apply(this);
    };
    this.headroom = new Headroom(this.$el, options).init();
  },
});
</script>
