<template>
  <div
    class="survey-questions-list"
  >
    <h3
      v-if="surveyTitle"
      v-html="surveyTitle"
    />
    <div
      v-if="surveyDescription"
      class="survey-info-card"
      v-html="surveyDescription"
    />
    <div v-if="!savedForm">
      <div
        v-for="(question, questionIdx) in normalizedQuestions"
        :key="questionIdx"
        class="survey-question-card"
      >
        <div
          class="survey-question-card__title"
        >
          {{ questionIdx + 1 }} - {{ question.title }}
        </div>
        <ol
          v-if="question.type === 'checkbox'"
          class="survey-question-card__list"
        >
          <li
            v-for="(choice, choiceIdx) in question.choices"
            :key="choiceIdx"
          >
            <label>
              <input
                type="checkbox"
                class="survey-question-card__checkbox"
                v-model="choice.value"
                true-value="1"
                false-value="0"
              >
              <span>{{ choice.title }}</span>
            </label>
          </li>
        </ol>
        <ol
          v-if="question.type === 'multiple_choice'"
          class="survey-question-card__list"
        >
          <li
            v-for="(choice, choiceIdx) in question.choices"
            :key="choiceIdx"
          >
            <label>
              <input
                type="radio"
                class="survey-question-card__radio"
                :name="`question-radio-${question.id}`"
                v-model="question.value"
                :value="choice.id"
              >
              <span>{{ choice.title }}</span>
            </label>
          </li>
        </ol>
        <div
          v-if="question.type === 'text'"
          class="survey-question-card__text"
        >
          <textarea
            type="text"
            name="title"
            class="survey-question-card__text-input"
            v-model="question.value"
          />
        </div>
      </div>
      <div
        class="survey-question-actions"
      >
        <button
          class="survey-question-actions__button"
          :disabled="savingForm"
          @click="saveQuestions"
        >
          Enviar Respuestas
        </button>
        <div
          class="survey-question-loader"
          v-if="savingForm"
        >
          <inline-svg name="three-dots-loader" />
        </div>
        <span
          v-if="invalidQuestion"
          class="survey-question-actions__error"
        >
          Falta responder la pregunta {{ invalidQuestion }}
        </span>
      </div>
    </div>
    <div
      v-if="savedForm"
      class="survey-msg-card"
    >
      ¡Gracias por responder la encuesta!
    </div>
  </div>
</template>

<script>
import * as answersApi from "../../api/devs-survey/answers";

const SELECTED_OPTION = '1';

export default {
  props: {
    questions: {
      type: Array,
      default() {
        return [];
      },
    },
    answersUrl: {
      type: String,
      default: null,
      required: true,
    },
    surveyTitle: {
      type: String,
      default: null,
    },
    surveyDescription: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      savingForm: false,
      savedForm: false,
      invalidQuestion: null,
      normalizedQuestions: this.questions,
    };
  },
  methods: {
    saveQuestions() {
      this.invalidQuestion = null;
      this.savingForm = true;
      const answers = [];
      let answer;

      try {
        this.normalizedQuestions.forEach((question, idx) => {
          answer = { id: question.id, type: question.type };
          this.assignAnswerValue(question, answer);

          if (!answer.value) {
            this.invalidQuestion = idx + 1;

            throw new Error('not answered question');
          }

          answers.push(answer);
        });
      } catch (e) {
        this.savingForm = false;

        return;
      }

      this.saveAnswers(answers);
    },
    saveAnswers(answers) {
      answersApi.create(this.answersUrl, answers)
        .then(() => {
          this.savingForm = false;
          this.savedForm = true;
        });
    },
    assignAnswerValue(question, answer) {
      switch (question.type) {
      case 'text':
      case 'multiple_choice':
        if (question.value) {
          answer.value = question.value;
        }
        break;
      default:
        question.choices.forEach((choice) => {
          if (choice.value && choice.value === SELECTED_OPTION) {
            answer.value = answer.value || [];
            answer.value.push(choice.id);
          }
        });
      }
    },
  },
};
</script>
