<template>
  <div>
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

const ENTER_ANYWAY_DELAY = 2000;

export default defineComponent({
  mounted() {
    const video = this.$el.querySelector('video');
    video.addEventListener('loadeddata', () => {
      this.reveal();
      document.addEventListener('mousemove', () => video.play(), { once: true });
    }, false);
    setTimeout(() => this.reveal(), ENTER_ANYWAY_DELAY);
  },
  methods: {
    reveal() {
      this.$el.querySelector('.our-story-hero').classList.add('our-story-hero--enter');
    },
  },
});
</script>
