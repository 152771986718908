/* eslint-env browser */
import smoothScroll from 'smoothscroll-polyfill';

smoothScroll.polyfill();

export default {
  created(el) {
    el.addEventListener('click', (event) => {
      const hash = event.currentTarget.hash;
      const target = document.querySelector(hash);
      if (target) {
        event.preventDefault();
        target.scrollIntoView({ behavior: 'smooth' });
        history.pushState(null, null, hash);
      }
    });
  },
};
