import { createApp } from 'vue';
import AOS from 'aos';
// import '../css/application.css'; TODO: uncomment after removing BEM

import ClickOutsideDirective from '../directives/click-outside';
import SmoothScrollDirective from '../directives/smooth-scroll-directive';
import InlineSvg from '../components/inline-svg.vue';
import GoogleMap from '../components/google-maps.vue';
import Questionnaire from '../components/devs-survey/questionnaire.vue';
import Headroom from '../components/web/headroom.vue';
import Dropdown from '../components/web/dropdown.vue';
import Story from '../components/web/story.vue';

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp({
    components: {
      Headroom,
      Dropdown,
      Story,
      GoogleMap,
    },
    data() {
      return {
        navigationOpen: false,
      };
    },
    mounted() {
      AOS.init({
        easing: 'ease-out-quart',
        duration: 1000,
      });
    },
    methods: {
      toggleNavigation() {
        this.navigationOpen = !this.navigationOpen;
      },
    },
  });

  app.component('inline-svg', InlineSvg);
  app.component('questionnaire', Questionnaire);
  app.directive('click-outside', ClickOutsideDirective);
  app.directive('smooth-scroll', SmoothScrollDirective);

  app.mount('#vue-app');

  return app;
});
