/* global google */
<template>
  <div class="google-map">
    <div class="google-map__canvas" />
  </div>
</template>
<script>
import GoogleMapsStyle from './google-maps-style';

export default {
  mounted() {
    const canvasEl = this.$el.querySelector('.google-map__canvas');
    const position = { lat: -33.39855897609268, lng: -70.58852837577469 };
    const myOptions = {
      zoom: 14,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      center: position,
      styles: GoogleMapsStyle,
      streetViewControl: false,
      rotateControl: false,
      mapTypeControl: false,
      scrollwheel: false,
    };
    const icon = {
      path: 'M27.414 19.414l-10 10c-0.781 0.781-2.047 0.781-2.828 0l-10-10c-0.781-0.781-0.781-2.047 0-2.828s2.047-0.781 2.828 0l6.586 6.586v-19.172c0-1.105 0.895-2 2-2s2 0.895 2 2v19.172l6.586-6.586c0.39-0.39 0.902-0.586 1.414-0.586s1.024 0.195 1.414 0.586c0.781 0.781 0.781 2.047 0 2.828z',
      anchor: new google.maps.Point('15', '25'),
      fillColor: '#fabc13',
      fillOpacity: 1,
      scale: 2,
      strokeWeight: 0,
    };
    const infoWindow = new google.maps.InfoWindow({
      content: `
        <p>
          <i class="icon icon--platanus-logo"></i> <strong>Platanus</strong><br />
          Avenida Vitacura 4380, Local 2<br />
          Vitacura, Santiago, Chile<br />
        </p>
      `,
    });

    const map = new google.maps.Map(canvasEl, myOptions);

    const marker = new google.maps.Marker({
      title: 'Platanus SpA',
      position,
      icon,
      map,
    });

    marker.addListener('click', () => {
      infoWindow.open(map, marker);
    });
  },
};
</script>
